/**
 * Pattern Explorer style sheet
 * 
 * Copyright (C) Sander Voerman <sander@savoerman.nl>, 2021-2023.
 */


/* Global variables */

$card-radius: 12px;
$card-border: 8px;
$card-field-radius: $card-radius - $card-border;
$widget-radius: 15px;

/*
 *   For now, these are hardcoded here in the SCSS.
 *   In the future, the frontend should be fully refactored to
 *   always use MUI components, so that MUI can handle the translation
 *   of elevation levels into shadows. But there may be some edge cases,
 *   such as our primary and secondary shadows of the cards in the
 *   toolbar. In any case, this is currently not an urgent matter.
 */
$mui-shadow-1: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
$mui-shadow-2: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
$mui-shadow-3: 0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12);
$mui-shadow-4: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
$mui-shadow-5: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12);
$mui-shadow-6: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);


:root {

  /* Context category colors */
  --pe-contextcat1-color: #c0c0c0;
  --pe-contextcat1-color-light: #d8d8d8;
  --pe-contextcat1-color-dark: #808080;
  --pe-contextcat2-color: #7ebfff;
  --pe-contextcat2-color-light: #bedfff;
  --pe-contextcat2-color-dark: #337cc5;
  --pe-contextcat3-color: #fe558e;
  --pe-contextcat3-color-light: #feaac6;
  --pe-contextcat3-color-dark: #d92e68;
  --pe-contextcat4-color: #ecc63f;
  --pe-contextcat4-color-light: #f5e29f;
  --pe-contextcat4-color-dark: #bc9818;
  --pe-contextcat5-color: #1db279;
  --pe-contextcat5-color-light: #bbe8d7;
  --pe-contextcat5-color-dark: #0c905e;

  /* Theme colors */
  --pe-neutral-color: #333035;
  --pe-sand-bg-color: #fdf3c2;
  --pe-air-bg-color: #f3faff;
  --pe-grey-bg-color: rgb(96, 93, 97);
  --pe-arrow-color: #808080;
  --pe-arrow-marker-color: #909090;
  --pe-arrow-color-selected: #008cff;
}


/* Global attributes */

* {
  overscroll-behavior: none;
}

body, textarea, input {
  font-family: Ubuntu, sans-serif;
}

body, div, li, p, main, footer, h1, h2, h3, h4, h5, h6 {
  user-select: none;
}


/* Top-level elements */

body {
  color: #111;
  touch-action: none;
}

html,
body,
#root,
#pe-root {
  margin: 0;
  height: 100%;
}

#pe-root {
  display: flex;
  flex-direction: column;
  background-color: var(--pe-air-bg-color);
}


/* Splash screen */

.pe-admin-bg {
  height: 100%;
  background-color: var(--pe-air-bg-color);
  padding: 20px;
  box-sizing: border-box;
}

.pe-splash-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: var(--pe-air-bg-color);
}

.pe-splash-card {
  color: var(--pe-arrow-color);
  font-size: 20px;
}

/* Title bar */

#pe-title-input {
  border: none;

  &:focus {
    outline: none;
  }
}


/* Cards */

.pe-card-badge {
  background: white;
  position: absolute;
  border-radius: $card-radius 0px;
  top: 0px;
  left: 0px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}




/* Canvas */

main {
  flex-direction: column;
  flex: 1 0 auto;
  display: flex;


  /* Card nodes */

  .react-flow__node-special {
    border-radius: $card-radius;
  }

  .pe-card {
    background: white;
    padding: $card-border;
    border-radius: $card-radius;
    box-shadow: $mui-shadow-2;
    box-sizing: border-box;
    height: 100%;

    &:hover {
      box-shadow: $mui-shadow-5;
    }

    * {
      box-sizing: border-box;
    }
  }

  .pe-card-delete, .pe-card-editmodebutton {
    display: none;
  }

  .selected .pe-card-delete,
  .selected .pe-card-editmodebutton,
  .pe-card-connect,
  .pe-arrow-delete {
    box-sizing: border-box;
    background-color: var(--pe-grey-bg-color);
    height: 24px;
    width: 24px;
    border-radius: 100%;
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      fill: white;
    }  
  }

  .selected .pe-card-delete {
    position: absolute;
    right: -8px;
    top: 12px;
  }

  .selected .pe-card-editmodebutton {
    position: absolute;
    right: -8px;
    bottom: 20px;
  }

  .pe-card-connect {
    position: absolute;
    left: -8px;
    top: 32px;
  }

  .pe-card-field {
    border-radius: $card-field-radius;
    color: white;
    height: 100%;
  }

  textarea {
    border: 0;
    color: white;
    background-color: transparent;
    resize: none;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    overflow: hidden;

    &:focus {
      outline: none;
    }
  
  }

  .react-flow__node a {
    font-weight: 700;
    color: #111;
  }

  .react-flow__node.dark-node {
    background: #0041d0;
    color: #f8f8f8;
  }

  .react-flow__node.dark {
    background: #557;
    color: #f8f8f8;
  }

  .react-flow__node-selectorNode {
    font-size: 12px;
    background: #f0f2f3;
    border: 1px solid 555;
    border-radius: 5px;
    text-align: center;
  }


  /* All handles */

  $handle-thickness: 20px;
  $handle-offset: 7px;
  $handle-radius: 10px;
  $handle-height-offset: ($handle-thickness - $handle-offset) * 2;
  $handle-width-offset: $handle-offset * 2;

  .react-flow__handle {
    border: 0;
    opacity: 0;
  }

  .react-flow__handle-left {
    width: $handle-thickness;
    left: -$handle-offset;
    border-radius: 0;
  }

  .react-flow__handle-right {
    width: $handle-thickness;
    right: -$handle-offset;
    border-radius: 0;
  }

  .react-flow__handle-top {
    height: $handle-thickness;
    top: -$handle-offset;
    border-radius: $handle-radius $handle-radius 0 0;
  }

  .react-flow__handle-bottom {
    height: $handle-thickness;
    bottom: -$handle-offset;
    border-radius: 0 0 $handle-radius $handle-radius;
  }


  /* Building block cards */

  .pe-card-bb {
    $bb-height: 180px;
    $bb-width: 140px;

    .pe-card-role {
      text-align: center;
      font-size: 10px;
      padding-top: 6px;
      height: 24px;
    }
  
    .pe-card-title,
    .pe-card-custom-title {
      text-align: center;
      overflow: hidden;
      font-size: 12px;
      font-weight: 700;
    }

    .pe-card-title {
      padding: 0 2px;
      height: 30px;
    }
  
    .pe-card-custom-title {
      height: 48px;
    }
    
    /* non-edit mode
    div.pe-card-custom-title {
    }
    */

    /* edit mode
    textarea.pe-card-custom-title {
    }
    */

    .pe-card-icon-title {
      position: relative;
      height: 90px;
    }
  
    .pe-card-icon {
      height: 75px;
      width: 124px;
      position: absolute;
      top: 15px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    .pe-card-comment,
    .pe-card-custom-comment {
      margin-top: 4px;
      padding: 0 4px;
      font-size: 10px;
      text-align: center;
      overflow: hidden;
      }

    .pe-card-comment {
      height: 42px;
    }

    .pe-card-custom-comment {
      height: 80px;
    }


    textarea.pe-card-comment,
    textarea.pe-card-custom-comment {
      &::placeholder {
        font-weight: normal;
      }
    }

    textarea.pe-card-comment {
      &::placeholder {
        line-height: 34px; /* workaround because vertical-align is not supported */
      }
    }

    textarea.pe-card-custom-comment {
      &::placeholder {
        line-height: 44px; /* workaround because vertical-align is not supported */
      }
    }

    .react-flow__handle-left, .react-flow__handle-right {
      height: $bb-height - $handle-height-offset;
    }
  
    .react-flow__handle-top, .react-flow__handle-bottom {
      width: $bb-width + $handle-width-offset;
    }

  }


  /* Thought cards */

  .pe-card-thought {
    $nonbb-height: 140px;
    $nonbb-width: 200px;
    background-image: url( '/style/thought.svg' );

    .pe-card-field {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .pe-card-role {
      text-align: center;
      font-size: 10px;
      padding-top: 6px;
      padding-bottom: 2px;
    }

    form {
      flex-grow: 1;
    }
  
    .pe-card-title {
      text-align: center;
      overflow: hidden;
      font-size: 12px;
      font-weight: 700;
      height: 70px;
      padding: 0 15px;
    }

    /* edit mode
    textarea.pe-card-title {
    }
    */
  
    .react-flow__handle-left, .react-flow__handle-right {
      height: $nonbb-height - $handle-height-offset;
    }
  
    .react-flow__handle-top, .react-flow__handle-bottom {
      width: $nonbb-width + $handle-width-offset;
    }

  }


  /* Consequence cards */

  .pe-card-consequence {
    $nonbb-height: 100px;
    $nonbb-width: 200px;

    .pe-card-role {
      text-align: center;
      font-size: 10px;
      padding-top: 6px;
      padding-bottom: 2px;
    }

    form {
      flex-grow: 1;
    }
  
    .pe-card-title {
      text-align: center;
      overflow: hidden;
      font-size: 12px;
      font-weight: 700;
      height: 60px;
    }

    /* edit mode
    textarea.pe-card-title {
    }
    */
  
    .react-flow__handle-left, .react-flow__handle-right {
      height: $nonbb-height - $handle-height-offset;
    }
  
    .react-flow__handle-top, .react-flow__handle-bottom {
      width: $nonbb-width + $handle-width-offset;
    }

  }


  /* Arrows */

  .pe-arrow-path-visual {
    stroke: var(--pe-arrow-color);
    stroke-width: 1.5;
    stroke-linecap: round;
  }

  .react-flow__edge.selected .pe-arrow-path-visual {
    stroke: var(--pe-arrow-color-selected);
    marker-end: url(#pe-arrow-marker-selected);
  }

  .react-flow__edge-path {
    cursor: pointer;
  }


  /* Controls */

  .react-flow__controls {
    top: auto;
    left: auto;
    right: 10px;
    position: absolute;
    z-index: 5;
    width: $widget-radius * 2;
    border-radius: $widget-radius;
    box-shadow: $mui-shadow-1;
    padding: 10px 0;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    &-button {
      box-sizing: border-box;
      background: transparent;
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 26px;
      cursor: pointer;
      user-select: none;

      svg {
        max-width: 12px;
        max-height: 12px;
      }
  
      &:hover {
        background: #f4f4f4;
      }

    }
  }

  
  /* 
    React Flow Attribution
    Instead of displaying this on the canvas, we have a 'powered by'
    attribution on the info dialog (Moritz Klack is fine with this,
    see his e-mail from 2022-02-03).
  */
  .react-flow__attribution {
    display: none;
  }
}


/*
  Arrow animation override
  TODO: refactor. Right now we are using the builtin RF CSS which includes
  animation for arrows that have the animated prop. We want to keep using
  this prop to allow different types of edges in the future that might not
  be animated in any case. But for the animated arrows, disabling the animation
  can be done more elegant than this way if at some point we take over the
  entire RF CSS and migrate everything to css-in-js. However, it is better
  to wait with that until after upgrading to React Flow 11.
*/
.pe-main-noanim .react-flow__edge.animated path,
.pe-main-noanim .react-flow__connection .animated {
  -webkit-animation: none;
  animation: none;
}


/* Toolbar */

.pe-toolbar {


  /* Toolbar items */

  /* 
  * Clickable area.
  * Note that the height is clipped by the bottom of the screen, so as
  * to prevent box shadows from appearing at the bottom of the cards
  * (since the card design is also clipped on the toolbar).
  */
  $nodedef-width: 80px;
  $nodedef-height: 80px;

  /*
  * Overlapping card visuals extend outward of the clickable area
  * in the top, left, and right directions by this amount of pixels.
  */
  $nodedef-outer: 12px;

  .pe-nodedef {
    margin: 8px 5px 0;
    width: $nodedef-width;
    height: $nodedef-height;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }
  }

  .pe-card {
    position: absolute;
    border-radius: $card-radius $card-radius 0 0;
    background-color: white;
    padding: $card-border $card-border 0;
    left: -$nodedef-outer;
    top: -$nodedef-outer;
    width: $nodedef-width + $nodedef-outer * 2;
    height: $nodedef-height + $nodedef-outer;

    /* 
     * Primary shadow.
     * This shadow is cast both upon the previous card and on the underlying
     * panel background.
     */
    box-shadow: $mui-shadow-2;

    /*
     * Secondary shadow.
     * This shadow is not cast from one card upon the previous card, but only
     * from the cards upon the underlying tab panel background, because the
     * cards as a whole are on a higher plane, so to speak, than the underlying
     * panel.
     */
    &::before {
      position: absolute;
      border-radius: $card-radius $card-radius 0 0;
      display: block;
      left: 0;
      top: 0;
      width: $nodedef-width + $nodedef-outer * 2;
      height: $nodedef-height + $nodedef-outer;
      box-shadow: $mui-shadow-1;
      content: "";
      z-index: -1;
    }
  }

  .pe-nodedef-field {
    color: white;
    font-size: 12px;
    text-align: center;
    height: 100%;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: $card-field-radius $card-field-radius 0 0;
  }

  .pe-card-bb .pe-nodedef-field {
    padding-top: 20px;
  }

  .pe-card-thought .pe-nodedef-field,
  .pe-card-consequence .pe-nodedef-field {
    padding-top: 6px;
  }

}


/* Context categories */

@for $i from 1 through 5 {
  .pe-card-contextcat#{$i} {
    .pe-card-badge svg {
      fill: var(--pe-contextcat#{$i}-color-dark);
    }

    textarea::placeholder {
      color: var(--pe-contextcat#{$i}-color-light);
      opacity: 1;
    }

    .pe-nodedef-field {
      background-color: var(--pe-contextcat#{$i}-color);
    }
  }

  .pe-card-bb.pe-card-contextcat#{$i},
  .pe-card-consequence.pe-card-contextcat#{$i} {
    .pe-card-field {
      background: var(--pe-contextcat#{$i}-color);
    }
  }

  .selected .pe-card-contextcat#{$i} {
    animation: pulse-#{$i} 2s ease-in-out infinite;
    animation-direction: alternate;
  }

  @keyframes pulse-#{$i} {
    from {
      box-shadow: 0px 0px 5px 2px var(--pe-contextcat#{$i}-color);
    }

    to {
      box-shadow: 0px 0px 15px 6px var(--pe-contextcat#{$i}-color);
    }
  }

}
